<template>
  <div class="ud-body">
    <div class="header">
      <i class="iconfont icon-shengyin_shiti"></i>
      <span
        >您的店铺为正式版,有效期至: <span class="date">2024-03-27</span></span
      >
      <span class="btn">立即续费</span>
    </div>
    <a-row :gutter="16" style="margin-top: 20px">
      <a-col :span="7">
        <div class="item-sub">
          <div class="title">
            <i class="iconfont icon-yue"></i>
            <span class="bold size-18">会员数</span>
          </div>
          <div class="content">
            <div>累计</div>
            <div class="bold size">10100</div>
            <div class="desc">(新增: 0)</div>
          </div>
          <div class="footer">
            <div class="footer-left">
              <div>今日<span class="bold">0</span></div>
              <div class="desc">(新增: 0)</div>
            </div>
            <a-divider
              type="vertical"
              style="height: 30px; background-color: #dee1e7"
            ></a-divider>
            <div class="footer-right">
              <div>本月<span class="bold">0</span></div>
              <div class="desc">(新增: 0)</div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="7">
        <div class="item-sub">
          <div class="title">
            <i class="iconfont icon-qianbao"></i>
            <span class="bold size-18">会员活跃度</span>
          </div>
          <div class="content">
            <div>累计</div>
            <div class="bold size">300</div>
          </div>
          <div class="footer">
            <div class="footer-left">
              <div>今日<span class="bold">0</span></div>
            </div>
            <a-divider
              type="vertical"
              style="height: 30px; background-color: #dee1e7"
            ></a-divider>
            <div class="footer-right">
              <div>本月<span class="bold">0</span></div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="7">
        <div class="item-sub">
          <div class="title">
            <i class="iconfont icon-youhuiquan"></i>
            <span class="bold size-18">优惠券使用张数</span>
          </div>
          <div class="content">
            <div>累计</div>
            <div class="bold size">1</div>
          </div>
          <div class="footer">
            <div class="footer-left">
              <div>今日<span class="bold">0</span></div>
            </div>
            <a-divider
              type="vertical"
              style="height: 30px; background-color: #dee1e7"
            ></a-divider>
            <div class="footer-right">
              <div>本月<span class="bold">0</span></div>
            </div>
          </div>
        </div></a-col
      >
      <a-col :span="3">
        <div class="item-sub nopadding">
          <div style="padding: 20px 20px 0">
            专属客服:
            <span class="bold" style="color: #527aff; margin-left: 8px"
              >远丞客服</span
            >
          </div>
          <div><img class="code-img" src="@/assets/erCode.png" /></div>
          <div class="desc" style="text-align: center">微信扫一扫</div>
          <div class="phone-info">
            <div>客服手机号</div>
            <div>
              <i
                style="font-size: 14px; margin-right: 8px"
                class="iconfont icon-phone-android"
              ></i
              >18059280268
            </div>
          </div>
        </div></a-col
      >
    </a-row>
    <a-row :gutter="16" style="margin-top: 20px">
      <a-col :span="12">
        <div class="table-wrap">
          <div class="table-sub">
            <div class="title">
              <i class="iconfont icon-huiyuanyuyue"></i>
              <span class="bold size-18">最新活动</span>
            </div>
            <div>
              <span
                @click="toggle('member', 0)"
                class="table-btn"
                :class="memberIndex === 0 ? 'table-btn-act' : ''"
                >本月</span
              >
              <span
                @click="toggle('member', 1)"
                class="table-btn"
                :class="memberIndex === 1 ? 'table-btn-act' : ''"
                >本年度</span
              >
            </div>
          </div>
          <div style="margin-top: 20px">
            <a-table
              :columns="memberColumns"
              :dataSource="memberList"
            ></a-table>
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="table-wrap">
          <div class="table-sub">
            <div class="title">
              <i class="iconfont icon-jifen"></i>
              <span class="bold size-18">会员积分排行榜</span>
            </div>
            <div>
              <span
                @click="toggle('integrate', 0)"
                class="table-btn"
                :class="integrateIndex === 0 ? 'table-btn-act' : ''"
                >本月</span
              >
              <span
                @click="toggle('integrate', 1)"
                class="table-btn"
                :class="integrateIndex === 1 ? 'table-btn-act' : ''"
                >本年度</span
              >
            </div>
          </div>
          <div style="margin-top: 20px">
            <a-table
              :columns="integrateColumns"
              :dataSource="integrateList"
            ></a-table>
          </div></div
      ></a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    corpId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      memberIndex: 0,
      integrateIndex: 0,
      memberList: [],
      integrateList: [],
      memberColumns: [
        {
          title: '排名',
          dataIndex: 'sort'
        },
        {
          title: '会员',
          dataIndex: 'name'
        },
        {
          title: '实充金额',
          dataIndex: 'money'
        },
        {
          title: '赠送金额',
          dataIndex: 'giveMoney'
        }
      ],
      integrateColumns: [
        {
          title: '排名',
          dataIndex: 'sort'
        },
        {
          title: '会员',
          dataIndex: 'name'
        },
        {
          title: '积分',
          dataIndex: 'integrate'
        }
      ]
    }
  },
  methods: {
    toggle(type, index) {
      if (type === 'member') {
        this.memberIndex = index
      } else {
        this.integrateIndex = index
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 50px;
  background: #fff;
  border-radius: 8px;
  line-height: 50px;
  padding: 0 20px;
  .iconfont {
    padding: 5px;
    background: #fff5e5;
    border-radius: 50%;
    color: #ff9900;
    margin-right: 8px;
  }
  .date {
    color: #ff9b3d;
    font-weight: bold;
    margin: 10px;
  }
  .btn {
    padding: 5px 15px;
    color: #fff;
    background: #4772ff;
    border-radius: 50px;
    cursor: pointer;
  }
}
.table-wrap {
  height: 300px;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  .table-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-btn-act {
    color: #fff !important;
    background: #4772ff;
  }
  .table-btn:nth-of-type(1) {
    // background: red;
    border-right: none;
    border-radius: 5px 0 0 5px;
  }
  .table-btn:nth-of-type(2) {
    border-left: none;
    border-radius: 0px 5px 5px 0px;
  }
  .table-btn {
    padding: 5px 10px;
    color: #636363;
    border: 1px solid #ebedf5;
    cursor: pointer;
  }
}
.nopadding {
  padding: 0 !important;
  position: relative;
}
.iconfont {
  font-size: 20px;
  color: #fff;
  margin-right: 10px;
}
.icon-yue {
  padding: 5px;
  background: #33a0fd;
  border-radius: 50%;
}
.icon-huiyuanyuyue {
  padding: 5px;
  background: #fe8340;
  border-radius: 50%;
}
.icon-jifen {
  padding: 5px;
  background: #ff728b;
  border-radius: 50%;
}
.icon-qianbao {
  padding: 5px;
  background: #00c3a0;
  border-radius: 50%;
}
.icon-youhuiquan {
  padding: 5px;
  background: #fec75a;
  border-radius: 50%;
}
.size-18 {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.item-sub {
  background: #fff;
  height: 235px;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;

  .content {
    height: 75px;
    margin: 20px;
    text-align: center;
  }

  .size {
    font-size: 20px;
    margin-top: 5px;
  }

  .desc {
    color: #a899ac;
  }
  .phone-info {
    width: 100%;
    background: #487aff;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0 0 5px 5px;
    padding: 10px 20px;
    font-size: 12px;
  }
  .code-img {
    width: 100px;
    height: 100px;
    display: block;
    margin: 5px auto;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-left {
      width: 40%;
      text-align: center;
    }
    .footer-right {
      width: 40%;
      text-align: center;
    }
  }
}
</style>